import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import {
  isFloat,
  isGreaterThanOrEqualToN,
  isLessThanOrEqualToN,
  isRequired,
  isRoundedToNDecimalPlaces,
} from '@/utilities/validations'
import store from '@/boot/store'

export const FIELDS = ['name', 'price', 'originRegion', 'generalLedgerCode']

export const nameField = () => {
  return {
    name: 'name',
    label: title(i18n.t('common.name')),
    type: 'text',
    rules: [isRequired],
  }
}

export const priceField = () => {
  return {
    name: 'price',
    type: 'number',
    label: title(i18n.t('common.price')),
    min: 0,
    max: 1000000,
    placeholder: '0.00',
    step: 0.01,
    rules: [
      v => isFloat(v),
      v => isGreaterThanOrEqualToN(v, 0),
      v => isLessThanOrEqualToN(v, 1000000),
      v => isRoundedToNDecimalPlaces(v, 2),
    ],
  }
}

export const originRegionField = () => {
  return {
    name: 'originRegion',
    label: title(i18n.t('billing.originRegion')),
    type: 'string',
    component: 'v-select',
    items: store.getters['billing/getRegions']?.data?.filter(r => r.origin) || [],
    itemText: 'displayName',
    itemValue: 'code',
    rules: [isRequired],
  }
}

export const generalLedgerCodeField = () => {
  return {
    name: 'generalLedgerCode',
    label: i18n.t('generalLedgerCode.navTitle'),
    type: 'string',
    component: 'v-select',
    items: store.getters['generalLedgerCode/getAll'] || [],
    itemText: 'name',
    itemValue: 'code',
    rules: [isRequired],
  }
}

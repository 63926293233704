<template>
  <huboo-full-screen-dialog :value="value" @input="$emit('input', $event)" :title="heading | title">
    <v-card class="pa-4 create-button-container mx-auto mt-4">
      <huboo-form
        :key="formKey"
        :title="$t('common.create') | title"
        @input="onInput"
        :fields="fields"
        :loading="loadingForm"
        lazy-validation
        id="manualPricingCreateForm"
      >
      </huboo-form>
      <v-btn
        color="primary"
        dark
        large
        width="100%"
        @click="onSubmit"
        id="manualPricingCreateModalButton"
      >
        {{ $t('common.create') }}
      </v-btn>
    </v-card>
  </huboo-full-screen-dialog>
</template>

<script>
import {
  generalLedgerCodeField,
  nameField,
  originRegionField,
  priceField,
} from '@/views/pages/billing/manualPricing/manualPricingModalHelpers'

export default {
  name: 'ManualPricingCreateModal',
  props: {
    value: Boolean,
  },
  data: () => ({
    formKey: 0,
    newModel: {},
  }),
  computed: {
    hubooBox() {
      return this.$store.getters['billing/getSelectedBox']?.hubooBox
    },
    heading() {
      return this.$t('billing.manualPricing')
    },
    fields() {
      return [nameField(), priceField(), originRegionField(), generalLedgerCodeField()].map(f => ({
        ...f,
        errorMessages: this.$store.getters['core/validationError'](f.name),
        title: f.label,
        text: this.newModel?.[f.name],
        value: Object.prototype.hasOwnProperty.call(f, 'value') || this.newModel?.[f.name],
      }))
    },
    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },
    loadingForm() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'POST',
        url: 'manual-price-list',
      })
    },
  },
  methods: {
    onInput({ field, value }) {
      this.newModel = { ...this.newModel, [field.name]: value }
    },
    async onSubmit() {
      await this.$store.dispatch('billing/postManualPriceLists', {
        ...this.newModel,
        hubooBox: this.hubooBox,
      })
      if (!this.hasErrors) {
        this.$emit('input', false)
        this.newModel = {}
        this.formKey += 1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-button-container {
  max-width: 600px;
}
</style>
